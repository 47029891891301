import BuyMeABeerButton from '../components/Buymeabeer';
import { EuiText, EuiSpacer, EuiLink
} from '@elastic/eui';
export default function FAQ() {
  
    return (
      <div>
        <h2>Frequently Asked Questions</h2>
        <EuiText>
          <hr />
          <h3>
            What is this tool?
          </h3>
          <h4>
            This tool is a follow-up to my other tools in the VNSH suite <EuiLink target='_blank' href='https://vnsh.link'>VNSH.Link</EuiLink> and <EuiLink target='_blank' href='https://vnsh.chat'>VNSH.Chat</EuiLink>. With privacy in mind I wanted to create a ephemeral email service so I didn't have to provide my real email address to ephemeral product sign-ups.</h4>
          <h3>
            Why does this tool exist?
          </h3>
          <h4>
            It's nice to not have to innundate your email inbox with newsletters, product trials, and other non-essential emails. This tool allows you to sign up for those services without providing your real email address.
          </h4>
          <h3>
            Where is the security?
          </h3>
          <h4>
            Due to the nature of email and the fact that this is a free service, there is no security. Please do not use this service for anything sensitive or important. This is a tool for signing up for newsletters, product trials, and other non-sensitive services.
          </h4>
          <h3>
            How can I buy you a beer?
          </h3>
          <h4>
            <strong>Anything</strong> is appreciated to keep projects like this alive and updated. If you want to buy me a beer, a case, or just help with hosting this tool click the button below! <br></br> <center><BuyMeABeerButton /></center>
          </h4>

        </EuiText>
      </div>
    );
  }
import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import config from '../config/config';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import GenerateEmail from '../components/Generate';

import {
  EuiSpacer,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiButton,
  EuiCallOut,
  EuiSelect,
  EuiCheckbox,
  EuiTextArea
} from '@elastic/eui';

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

function Home() {
  const { userData, isMobileDevice } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")

  return (
    <>
    <Header />
      <GenerateEmail />
    <Footer />
    </>
  );
}

export default Home;

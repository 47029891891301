import React, { useState, useEffect } from 'react';
import config from '../config/config';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import FAQ from '../components/Faq';

import {
  EuiModal,
  EuiModalBody,
  EuiSpacer,
  EuiFieldText,
  EuiText,
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiBadge,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlexItem,
  EuiFlexGroup
} from '@elastic/eui';

function DeviceDetector() {
  // Access the user agent string
  const userAgent = navigator.userAgent;

  // Define regular expressions for common mobile user agents
  const mobileUserAgentRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

  // Check if the user agent matches a mobile device
  const isMobileDevice = mobileUserAgentRegex.test(userAgent);

  return isMobileDevice;
}

function Header(){
  const { pendingMessages, updateIsMobileDevice, chat, socket, chatUsers, chatSettings, updateRoomSettingsModal } = useGlobalContext();
  const [showCallout, setShowCallout] = useState(!Cookies.get('devack')); // Check if cookie is set
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [share, setShare] = useState(false);
  const [users, setUsers] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    console.log("Is Mobile", DeviceDetector());
    updateIsMobileDevice(DeviceDetector());
  },[]);

  const handleShare = (newShare) => {
    setShare(newShare);
  }

  const handleUsers = (newShare) => {
    setUsers(newShare);
  }

  const handleSettings = (newShare) => {
    updateRoomSettingsModal(newShare);
  }

  const openFlyout = () => {
    setIsFlyoutVisible(true);
  };
  
  const closeFlyout = () => {
    setIsFlyoutVisible(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Link copied to clipboard');
    });
  };

    return (
        <>
        <EuiHeader>
          <EuiHeaderSectionItem border="right">
            <EuiHeaderLink href="/">
              <img alt="Vanishing Chat" style={{height: "32px", marginTop: "3px"}} src='/assets/images/vanishinglink128.gif' />
            </EuiHeaderLink>
            <EuiHeaderLink href="/">
                {config.app.name}
            </EuiHeaderLink>
          </EuiHeaderSectionItem>
    
          <EuiHeaderLinks>
            <EuiHeaderLink onClick={() => window.open("https://vnsh.link")}>
              Ephemeral Links
            </EuiHeaderLink>
            <EuiHeaderLink onClick={() => window.open("https://vnsh.chat")}>
              Private Chats
            </EuiHeaderLink>
            <EuiHeaderLink onClick={() => openFlyout()}>
              FAQ
              </EuiHeaderLink>
          </EuiHeaderLinks>
        </EuiHeader>

        {isFlyoutVisible && (
          <EuiFlyout
            ownFocus
            onClose={closeFlyout}
            aria-labelledby="helpFlyoutTitle"
            size="m"
          >
            <EuiFlyoutBody>
              <EuiText>
                <FAQ />
              </EuiText>
            </EuiFlyoutBody>
          </EuiFlyout>
        )}

        </>
      );
}

export default Header;
import React, { useEffect, useState } from 'react';
import { EuiButton, EuiSpacer, EuiPanel, EuiFormRow, EuiFlexGroup, EuiFlexItem, EuiFieldText, EuiLink, EuiCallOut, setEuiDevProviderWarning } from '@elastic/eui';
import config from '../config/config';

function GenerateEmail() {
    const [email, setEmail] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(true);

    const closeModal = () => setIsModalVisible(false);
    const showModal = () => setIsModalVisible(true);
    const [ error, setError ] = useState('')

    useEffect(() => {
        generateEmail();
    }, []);

    const generateEmail = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let email = '';
        for (let i = 0; i < 12; i++) {
            email += chars[Math.floor(Math.random() * chars.length)];
        }
        email += '@vnsh.net';
        setEmail(email);
    }

    const validateEmail = (email) => {
        // make sure the part before the @ is allowed
        console.log(email);
        const re = /\S+@\S+\.\S+/;

        if(re.test(email)){
            const username = email.split('@')[0];
            console.log(username);
            const blockedUsernames = config.restrictions.blockedKeys.includes(username);
            console.log(blockedUsernames);
            if (blockedUsernames) {
                setError("Invalid / Disallowed email address");
                return false;
            }
            return true;
        }else{
            setError("Invalid email address");
            return false;
        }

    }

    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    const openInbox = () => {
        if(!validateEmail(email)){
            return;
        }
        window.location.href = `/inbox/${email}`;
    }

    const handleCopy = (e) => {
        navigator.clipboard.writeText(email);
    }

    return (
        <>
            <EuiSpacer size='l' />
            <EuiPanel style={{ width: 'auto', maxWidth: '400px', margin: 'auto' }}>
                <>
                {error && <EuiCallOut title={error} color='danger' iconType='alert' />}
                <EuiFormRow label='Your new ephemeral email address' fullWidth>
                    <EuiFlexGroup>
                    <EuiFlexItem grow={true}>
                        <EuiFieldText
                        name='email'
                        id='email'
                        value={email}
                        onChange={(e) => handleChange(e)} // Pass the event directly to your handleChange function
                        checked='false'
                        placeholder='Type custom key or click Random'
                        autoFocus
                        readOnly={true}
                        onClick={(e) => handleCopy(e)}
                        append={
                        <>
                            <EuiButton size='m' type='button' onClick={generateEmail} style={{maxWidth: "150px"}}>
                                Randomize
                            </EuiButton>
                        </>
                        }
                        />
                    </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFormRow>
                <EuiFormRow fullWidth>
                    <EuiFlexGroup justifyContent="center">
                    <EuiFlexItem grow={true}>
                        <EuiButton 
                        type='button' 
                        onClick={openInbox}
                        disabled={!email}  
                        >
                        Open Inbox
                        </EuiButton>
                    </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFormRow>
            
                </>
            </EuiPanel>
        </>
    );
}

export default GenerateEmail;
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import config from '../config/config';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider

import {
  EuiSpacer,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiButton,
  EuiCallOut,
  EuiSelect,
  EuiCheckbox,
  EuiTextArea,
  EuiDataGrid,
  EuiLink
} from '@elastic/eui';

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

function Home() {
  const { userData, isMobileDevice } = useGlobalContext();
  const [ loading, setLoading ] = useState(false);
  const [ isFetching, setIsFetching ]  = useState(true);
  const [ error, setError ] = useState("")
  const { address, email } = useParams();
  const [ data, setData ] = useState(null);
  const [ lastFetch, setLastFetch ] = useState(0);

  const viewEmail = (emailId) => {
    window.location.href = `/inbox/${address}/${emailId}`;
  }

  const handleFetch = () => {
    setIsFetching(true);
    fetchEmails();
  }
  
  const deleteEmail = (emailId) => {
    console.log("Deleting email", emailId);
    let apiUrl = "";
    // Define the URL with the dynamic thiskey parameter
    if (process.env.NODE_ENV === 'development') {
      console.log('Running in development mode');
      apiUrl = `http://${window.location.hostname}:3050/emails/${address}/${emailId}`;
    } else if (process.env.NODE_ENV === 'production') {
      apiUrl = `/emails/${address}/${emailId}`;
    }
    fetch(`${apiUrl}`, {
      method: 'DELETE'
    }).then((response) => {
      handleFetch();
      console.log(response);
    }).catch((error) => {
      setError(error.message); // Store the error message
      console.error(error);
      setIsFetching(false);
    });
  }

  const fetchEmails = () => {
    let apiUrl = "";
    // Define the URL with the dynamic thiskey parameter
    if (process.env.NODE_ENV === 'development') {
      console.log('Running in development mode');
      apiUrl = `http://${window.location.hostname}:3050/emails/${address.toLowerCase()}`;
    } else if (process.env.NODE_ENV === 'production') {
      apiUrl = `/emails/${address.toLowerCase()}`;
    }
    

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => {
        //console.log(response);
        if (!response.ok) {
          // Handle 400 and 404 errors
          if (response.status === 400 || response.status === 404) {
            throw new Error('Uhoh, Looks like that mailbox can not be found.');
          } else if (response.status === 401) {
            throw new Error(`Cannot read email from a restricted inbox.`);
          } else{
            throw new Error(`Something went wrong. Try again later.`);
          }
        }
        return response.json();
      })
      .then((responseData) => {
        // Update the state with the fetched data
        console.log(responseData);
        setData(responseData);
        setIsFetching(false);
        setError("");
      })
      .catch((error) => {
        setError(error.message); // Store the error message
        console.error(error);
        setIsFetching(false);
      });
      setLastFetch(Date.now());
    }


  useEffect(() => {
    fetchEmails();
    setInterval(() => {
      setIsFetching(true);
      fetchEmails();
    } , 15000);
  }, []);

  return (
    <>
    <Header />
    <EuiPanel style={{ width: 'auto', margin: 'auto' }}>
      <EuiFlexGroup >
        <EuiFlexItem style={{ minWidth: '600px' }}>
          <EuiFormRow label="Inbox for: " style={{ maxWidth: '100%' }}>
            <EuiFieldText
              value={address}
              readOnly
              fullWidth
              onClick={() => navigator.clipboard.writeText(address)}
              append={
                <>
                <EuiButton size="m" onClick={() => navigator.clipboard.writeText(address)}>
                  Copy Address
                </EuiButton>
                <EuiButton size="m" onClick={() => navigator.clipboard.writeText(window.location.href)}>
                  Copy Link
                </EuiButton>
                </>
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
    {error && (
      <EuiCallOut title="Error" color="danger" iconType="alert">
        <p>{error}</p>
      </EuiCallOut>
    )}

    <EuiSpacer size='l' />
    
      <EuiPanel style={{ width: 'auto', margin: 'auto' }}>

    {data && Object.keys(data).length > 0 ? (
      <EuiDataGrid
        
        columns={[
          {
            id: 'emailId',
            displayAsText: 'Email ID',
          },
          {
            id: 'sender',
            displayAsText: 'Sender',
          },
          {
            id: 'subject',
            displayAsText: 'Subject',
          },
          {
            id: 'Date',
            displayAsText: 'Timestamp',
          },
          {
            id: 'Actions',
            displayAsText: 'Actions',
          },
        ]}
        columnVisibility={{ visibleColumns: ['emailId', 'sender', 'subject', 'Date', 'Actions'] }}
        rowCount={Object.keys(data).length}
        renderCellValue={({ rowIndex, columnId }) => {
          const emailId = Object.keys(data)[rowIndex];
          const emailDetails = JSON.parse(data[emailId]);
          //console.log(emailDetails);
          if (columnId === 'emailId') {
            return emailId;
          } else if(columnId === 'Actions') {
            return <><EuiButton size="xs" onClick={() => viewEmail(emailId)} fill>Read</EuiButton><EuiButton size="xs" onClick={() => {deleteEmail(emailId)}} fill>Delete</EuiButton></>;
          }else {
            return emailDetails[columnId];
          }
        }}
      />
    ): (
        <center><p>No current emails.</p></center>
      )}
      </EuiPanel>
      <EuiSpacer size='l' />
      {isFetching ? (<center><p>Fetching new mail...</p></center>) : (<center><p>Last fetched: {new Date(lastFetch).toLocaleString()} <EuiLink onClick={() => handleFetch()}>Fetch Now</EuiLink></p></center>)}
      
    <Footer />
    </>
  );
}

export default Home;

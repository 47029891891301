import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { EuiProvider } from '@elastic/eui'
import '@elastic/eui/dist/eui_theme_dark.css'
import Home from './pages/Home';
import config from './config/config';
import Inbox from './pages/Inbox';
import Email from './pages/Email';
import { GlobalProvider } from './components/Global';

function App() {

  return (
    <>
    <GlobalProvider>
      <EuiProvider colorMode={'dark'} >
        <Router>
          <Routes>
            <Route path="/" exact element={<Home/>} />
            <Route path="/inbox/:address" element={<Inbox />} />
            <Route path="/inbox/:address/:email" element={<Email />} />
          </Routes>    
        </Router>
      </EuiProvider>
    </GlobalProvider>
    </>
  );
}

export default App;

const config = {
    app: {
        name: process.env.APPNAME || "Vanishing Email Generator",
        title: process.env.APPTITLE || "VNSH.net - Ephemeral Email Tool",
        shortName: process.env.APPSHORTNAME || "Vanishing Email",
        tagline: process.env.APPTAGLINE || "",
        baseDomain: process.env.APPBASEDOMAIN || "VNSH.net",
        version: process.env.version || "v1.0.0rc7"
    },
    restrictions: {
        blockedKeys: ["admin","wp-admin","administrator","login","delete","logincallback","docs","help","donate","support","changelog","report","dmca","home","index","http","html","aspx","security", "webmaster"]
    },
    developer: {
        name: "Ron Egli",
        github: "https://github.com/smugzombie"
    }
  };
  
  export default config;
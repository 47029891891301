import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import config from '../config/config';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider

import {
  EuiSpacer,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiButton,
  EuiCallOut,
  EuiSelect,
  EuiCheckbox,
  EuiTextArea,
  EuiDescriptionList,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiLink
} from '@elastic/eui';

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

function Email() {
  const { userData, isMobileDevice } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")
  const { address, email } = useParams();
  const [ data, setData ] = useState(null);

  const deleteEmail = (emailId) => {
    console.log("Deleting email", emailId);
    let apiUrl = "";
    // Define the URL with the dynamic thiskey parameter
    if (process.env.NODE_ENV === 'development') {
      console.log('Running in development mode');
      apiUrl = `http://${window.location.hostname}:3050/emails/${address}/${emailId}`;
    } else if (process.env.NODE_ENV === 'production') {
      apiUrl = `/emails/${address}/${emailId}`;
    }
    fetch(`${apiUrl}`, {
      method: 'DELETE'
    }).then((response) => {
      window.location.href = `/inbox/${address}`;
    }).catch((error) => {
      setError(error.message); // Store the error message
      console.error(error);
    });
  }


  useEffect(() => {
    // Define the URL with the dynamic thiskey parameter
    let apiUrl = "";
    // Define the URL with the dynamic thiskey parameter
    if (process.env.NODE_ENV === 'development') {
      console.log('Running in development mode');
      apiUrl = `http://${window.location.hostname}:3050/emails/${address.toLowerCase()}/${email}`;
    } else if (process.env.NODE_ENV === 'production') {
      apiUrl = `/emails/${address.toLowerCase()}/${email}`;
    }
    setLoading(true);

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          // Handle 400 and 404 errors
          if (response.status === 400 || response.status === 404) {
            throw new Error('Uhoh, We cannot find that email.');
          } else {
            throw new Error(`Failed to fetch data`);
          }
        }
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData)
        // Update the state with the fetched data
        setData(responseData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message); // Store the error message
        console.error(error);
      });

  
  }, [email]);

  return (
    <>
    <Header />
    {loading && <EuiCallOut title="Loading email..." />}
    {error && <EuiCallOut title={error} color="danger" />}
    <EuiPanel style={{ width: 'auto', margin: 'auto' }}>
      <EuiFlexGroup >
        <EuiFlexItem style={{ minWidth: '600px' }}>
          <EuiFormRow style={{ maxWidth: '100%' }}>
            <>
            <EuiLink href={`/inbox/${address}`}>Back to Inbox</EuiLink> {" - "} 
            <EuiLink onClick={() => {deleteEmail(email)}}>Delete this Email</EuiLink>
            </>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
    {data && (
      <>
      <EuiSpacer size='l' />
      <EuiPanel style={{ width: 'auto', margin: 'auto', maxWidth: '80%' }}>
        <EuiDescriptionList>
          <EuiDescriptionListTitle>Email ID</EuiDescriptionListTitle>
          <EuiDescriptionListDescription>{email}</EuiDescriptionListDescription>

          <EuiDescriptionListTitle>Recipient</EuiDescriptionListTitle>
          <EuiDescriptionListDescription>{data.recipient}</EuiDescriptionListDescription>

          <EuiDescriptionListTitle>Sender</EuiDescriptionListTitle>
          <EuiDescriptionListDescription>{data.sender}</EuiDescriptionListDescription>

          <EuiDescriptionListTitle>Subject</EuiDescriptionListTitle>
          <EuiDescriptionListDescription>{data.subject}</EuiDescriptionListDescription>

          <EuiDescriptionListTitle>Message</EuiDescriptionListTitle>
          <EuiPanel style={{ width: 'auto', margin: 'auto', padding: '20px' }}>
            <EuiDescriptionListDescription dangerouslySetInnerHTML={{ __html: data['body-html'] || data['body-plain']}}></EuiDescriptionListDescription>
          </EuiPanel>
        </EuiDescriptionList>
        </EuiPanel>
      </>
    )}
    <Footer />
    </>
  );
}

export default Email;

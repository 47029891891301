// GlobalContext.js
import React, { createContext, useContext, useState } from 'react';

// Create the context
const GlobalContext = createContext();

// Create the context provider
export const GlobalProvider = ({ children }) => {
  // Define your state variables here
  const [userData, setUserData] = useState({});
  const [isLoadingUserData, setIsLoadingUserData ] = useState(true);
  const [isMobileDevice, setIsMobileDevice ] = useState(false);

  // Create functions to update the variables
  const updateUserData = (newValue) => {
    setUserData(newValue);
  };

  const updateIsLoadingUserData = (newValue) => {
    setIsLoadingUserData(newValue)
  }

  const updateIsMobileDevice = (newValue) => {
    setIsMobileDevice(newValue)
  }

  // Define the context value with state and functions
  const contextValue = {
    userData,
    isLoadingUserData,
    isMobileDevice,
    updateUserData,
    updateIsLoadingUserData,
    updateIsMobileDevice,
  };

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

// Create a custom hook to access the context
export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
